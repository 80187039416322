/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import React from "react"

const AngleCover = ({
  rotation,
  topMargin = "0",
  botMargin = "0",
  height = "100px",
  backgroundColor = "#fff",
  headerType = "",
}) => (
  <div
    css={css`
      position: relative;
      background-color: ${backgroundColor};
      width: 125%;
      height: ${height};
      z-index: 30;
      margin-top: ${topMargin};
      margin-bottom: ${botMargin};
      margin-left: -5px;
      margin-right: -5px;
      padding: 0 20px;
      transform: rotate(${rotation});
      @media (min-width: 1200px) {
        ${rotation === "-3deg"
          ? "transform: rotate(-2deg);"
          : "transform: rotate(2deg);"}
      }
      @media (min-width: 1600px) {
        ${rotation === "-3deg"
          ? "transform: rotate(-1deg);"
          : "transform: rotate(1deg);"}
      }
    `}
  />
)
export default AngleCover
