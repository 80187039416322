import React from "react"
import Helmet from "react-helmet"
import { Row, Col } from "reactstrap"
import Header from "../components/header"
import Footer from "../components/footer"

import favicon16 from "../assets/icons/logo-16.png"
import favicon32 from "../assets/icons/logo-32.png"
import favicon64 from "../assets/icons/logo-64.png"

import { StaticQuery, graphql } from "gatsby"

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"
import "jquery/dist/jquery.slim.min.js"
import "./layout.css"

const Layout = ({ children, headerType, headerText, subText }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <React.Fragment>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: "description",
              content: data.site.siteMetadata.description,
            },
            { name: "keywords", content: data.site.siteMetadata.keywords },
          ]}
          link={[
            {
              rel: "icon",
              type: "image/png",
              sizes: "16x16",
              href: `${favicon16}`,
            },
            {
              rel: "icon",
              type: "image/png",
              sizes: "32x32",
              href: `${favicon32}`,
            },
            { rel: "shortcut icon", type: "image/png", href: `${favicon64}` },
          ]}
        />
        <Row noGutters={true}>
          <Header
            headerType={headerType}
            headerText={headerText}
            subText={subText}
          />
        </Row>
        <Row noGutters={true}>
          <Col>{children}</Col>
        </Row>
        <Row noGutters={true}>
          <Footer />
        </Row>
      </React.Fragment>
    )}
  />
)

export default Layout
