import React from 'react'
import {css} from 'emotion'
import gatsbyIcon from '../assets/icons/gatsby-icon.png'

const Footer = () => (
<div className={
		css`
			color: #666;
			text-align: center;
			width: 100%;
			margin: 20px 0 20px 0;
		`
	}>Built with <a href="https://www.gatsbyjs.org/" target="_blank" alt="Built with Gatsby" rel="noopener noreferrer"> <img src={gatsbyIcon} width="20" alt="Gatsby icon"/> GatsbyJS</a> in Austin, TX
</div>
)

export default Footer